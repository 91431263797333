<template>
    <SbsModal
        v-model="modalModel"
        modalContainerClass="ConfirmModalContainer"
        maxWidth="627">
        <div class="ConfirmModal">
            <slot name="prepend"></slot>
            <slot name="label">
                <h4
                    v-html="label"
                    class="ConfirmModal__Title"></h4>
            </slot>
            <p
                v-if="subLabel"
                v-html="subLabel"
                class="ConfirmModal__Subtitle">
            </p>
            <slot name="additionalContent"></slot>
            <div class="ConfirmModal__Actions">
                <SbsButton
                    class="ConfirmModal__ActionsButton"
                    :bordered="false"
                    backgroundTheme="primary"
                    colorTheme="white"
                    size="large"
                    :text="buttonRemoveTitle"
                    :loading="loading"
                    static
                    fullWidth
                    @click="handleConfirm" />
                <SbsButton
                    class="ConfirmModal__ActionsButton"
                    backgroundTheme="gray-ultra-light"
                    borderTheme="gray"
                    colorTheme="black"
                    size="large"
                    :text="buttonCancelTitle"
                    :disabled="loading"
                    bordered
                    static
                    fullWidth
                    @click="handleCancel" />
            </div>
        </div>
        <SbsCheckbox
            v-if="hasShowAgainNote"
            v-model="notShowAgain"
            class="ConfirmModal__Checkbox"
            hoverBackground="transparent"
            hoverColor="gray-deep"
            controlTheme="gray-ultra-light"
            :label="showAgainNote" />
    </SbsModal>
</template>

<script>
export default {
    name: 'ConfirmModal',
    emits: ['update:modelValue', 'confirm', 'cancel', 'notShowAgain', 'close'],
    data() {
        return {
            notShowAgain: false,
        };
    },
    computed: {
        modalModel: {
            get() {
                return this.modelValue;
            },
            set(value) {
                if (this.loading) return;

                this.$emit('update:modelValue', value);

                if (!value) {
                    this.$emit('close');
                }
            },
        },
    },
    methods: {
        handleConfirm() {
            this.$emit('confirm', this.notShowAgain);
            this.$emit('notShowAgain', this.notShowAgain);
            this.notShowAgain = false;
        },
        handleCancel() {
            this.$emit('cancel');
        },
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
            required: true,
        },
        subLabel: {
            type: String,
            default: '',
        },
        showAgainNote: {
            type: String,
            default: 'Не показывать больше',
            required: false,
        },
        hasShowAgainNote: {
            type: Boolean,
            default: false,
        },
        buttonRemoveTitle: {
            type: String,
            default: 'Да, выйти',
        },
        buttonCancelTitle: {
            type: String,
            default: 'Нет, я передумал-(а)',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.ConfirmModal {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ConfirmModal__Title {
    text-align: center;

    @include typo-h3-bold;
}

.ConfirmModal__Subtitle {
    margin-top: 10px;
    text-align: center;

    @include typo-additional-book;
}

.ConfirmModal__Actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    width: 100%;
    margin-top: 20px;
}
</style>

<style lang="scss">
.ConfirmModal__Checkbox {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid $gray-light;

    .sbs-checkbox__item {
        align-items: flex-start;
        padding: 0;
    }

    .sbs-checkbox__control-wrapper {
        padding-top: 4px;
    }

    .sbs-checkbox__control {
        width: 20px;
        height: 20px;
    }
}

.ConfirmModalContainer {
    z-index: 200;
}
</style>
