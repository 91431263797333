const RouterViewWrapper = () => import('@/views/RouterViewWrapper.vue');
const AdminView = () => import('@/admin/views/AdminView.vue');
const AdminLongreadUpdateView = () => import('@/admin/views/Longread/AdminLongreadUpdateView.vue');
const AdminProjectsMainViewComponent = () => import('@/admin/views/AdminProjectsMainView.vue');
const LearningSolutionStructureComponent = () => import('@/admin/views/LearningSolutionStructureMainView.vue');
const AdminDevelopmentCenterMainView = () => import('@/admin/views/DevelopmentCenter/AdminDevelopmentCenterMainView.vue');
const AdminProjectVersionsMainView = () => import('@/admin/views/AdminProjectVersionsMainView.vue');
const VersionReviewMainView = () => import('@/admin/views/VersionReviewMainView.vue');
const VersionSettingsMainView = () => import('@/admin/views/VersionSettingsMainView.vue');
const VersionAssignments = () => import('@/admin/views/VersionAssignments.vue');
const VersionStatisticsView = () => import('@/admin/views/VersionStatisticsView.vue');
const VersionStudentsMainView = () => import('@/admin/views/VersionStudentsMainView.vue');
const VersionStudentMainView = () => import('@/admin/views/VersionStudentMainView.vue');
const PreviewView = () => import('@/admin/views/PreviewView.vue');
const AdminReports = () => import('@/admin/views/AdminReports.vue');
const TaskMainView = () => import('@/admin/views/TaskMainView.vue');
const EventsMainView = () => import('@/admin/views/EventsMainView.vue');
const GlossaryView = () => import('@/admin/views/GlossaryView.vue');

const EventAttendanceView = () => import('@/admin/views/EventAttendanceView.vue');
const MainFooter = () => import('@/layouts/Parts/MainFooter.vue');

export default [
    {
        path: 'admin',
        component: AdminView,
        children: [
            {
                path: 'centers/:developmentCenterId',
                name: 'AdminDevelopmentCenterMainView',
                components: {
                    default: AdminDevelopmentCenterMainView,
                    MainFooter,
                },
            },
            {
                path: 'centers/:developmentCenterId/reports',
                name: 'AdminReports',
                components: {
                    default: AdminReports,
                    MainFooter,
                },
            },
            {
                path: 'centers/:developmentCenterId?/solutions',
                name: 'AdminProjectsList',
                components: {
                    default: AdminProjectsMainViewComponent,
                    MainFooter,
                },
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/versions',
                name: 'AdminProjectVersionsMainView',
                components: {
                    default: AdminProjectVersionsMainView,
                    MainFooter,
                },
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/versions/:versionId',
                name: 'VersionReviewMainView',
                components: {
                    default: VersionReviewMainView,
                    MainFooter,
                },
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/versions/:versionId/settings',
                name: 'VersionSettingsMainView',
                components: {
                    default: VersionSettingsMainView,
                    MainFooter,
                },
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/versions/:versionId/events',
                name: 'EventsMainView',
                component: EventsMainView,
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/versions/:versionId/glossary',
                name: 'GlossaryView',
                components: {
                    default: GlossaryView,
                    MainFooter,
                },
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/versions/:versionId/events/:eventId',
                name: 'EventAttendanceView',
                component: EventAttendanceView,
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/versions/:versionId/statistics',
                name: 'VersionStatisticsView',
                components: {
                    default: VersionStatisticsView,
                    MainFooter,
                },
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/versions/:versionId/students',
                name: 'VersionStudentsMainView',
                components: {
                    default: VersionStudentsMainView,
                    MainFooter,
                },
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/versions/:versionId/students/:studentId',
                name: 'VersionStudentMainView',
                components: {
                    default: VersionStudentMainView,
                    MainFooter,
                },
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/versions/:versionId/homeworks',
                name: 'VersionAssignments',
                components: {
                    default: VersionAssignments,
                    MainFooter,
                },
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/versions/:versionId/homeworks/:homeworkId',
                name: 'TaskMainView',
                components: {
                    default: TaskMainView,
                    MainFooter,
                },
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/version/:versionId/update',
                name: 'LearningSolutionStructure',
                components: {
                    default: LearningSolutionStructureComponent,
                    MainFooter,
                },
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/version/:versionId/preview',
                name: 'VersionPreviewView',
                component: PreviewView,
            },
            {
                path: 'centers/:developmentCenterId/solutions/:solutionId/version/:versionId/ls',
                component: RouterViewWrapper,
                children: [
                    {
                        path: ':longreadId',
                        component: RouterViewWrapper,
                        children: [
                            {
                                path: '',
                                name: 'AdminLongreadMainView',
                                redirect: {
                                    name: 'AdminLongreadUpdateView',
                                },
                            },
                            {
                                path: 'update',
                                name: 'AdminLongreadUpdateView',
                                component: AdminLongreadUpdateView,
                            },
                            {
                                path: 'preview',
                                name: 'AdminLongreadPreviewView',
                                component: PreviewView,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
